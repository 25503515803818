<template>
  <div :class="`hisotry-filter ${themeName}`">
    <div class="date-filter-top">
      <div style="margin-right: 10px">Date</div>
      <FilterButton ref="FilterButton" :eventFilter="eventFilter" @save="onSaveFilter"></FilterButton>
    </div>
    <DateFilter ref="DateFilter" :dateList="dateList" @update="onUpdateDate" @download="onClickDownload" style="margin-top: 8px"></DateFilter>
    <DateList v-if="!dateLoading" :selectDate="selectDate" :data="dateList" @click="onChangeSite" style="margin-top: 15px"></DateList>
    <div v-else class="progress-circular">
      <v-progress-circular indeterminate color="#D8D9DA" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FilterButton from './FilterButton'
import DateFilter from './DateFilter'
import DateList from './DateList'
import moment from 'moment'
import { alert } from '@/api'
import Util from '@/util'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { FilterButton, DateFilter, DateList },
  data() {
    return {
      selectDate: -1,
      eventFilter: [],
      period: [],
      tiemoutId: null,
      dateLoading: false,
    }
  },
  watch: {
    dateList(newVal, oldVal) {
      this.$store.dispatch('setHistoryViewSelectedDateListAction', newVal)
      if (newVal && !newVal.length && !oldVal.length) {
        this.$store.dispatch('setCurrentCam', this.camList[0])
      }
    },
  },
  computed: {
    ...mapState(['logListv2', 'camList', 'eventTypeList', 'user']),
    calendarMinDate() {
      const history = moment(this.user.history_start)
      const days = moment().diff(history, 'days')
      return moment()
        .startOf('day')
        .subtract(days + 1, 'days')
    },
    initDates() {
      // 검색 가능 기간의 default는 1주 전이지만 계약기간이 이보다 작다면 default 검색을 검색 가능한 기간으로 설정한다.
      let prevWeek = moment().subtract(6, 'days')
      let startDate = moment(this.calendarMinDate).add(1, 'days')
      if (prevWeek.isAfter(startDate)) {
        startDate = prevWeek.format('YYYY-MM-DD')
      } else {
        startDate = startDate.format('YYYY-MM-DD')
      }
      const endDate = moment().format('YYYY-MM-DD')
      return [startDate, endDate]
    },
    dateList() {
      const dateList = []
      const dateMap = {}
      this.filterdList.forEach((log) => {
        const date = moment(log.created_at).format('YYYY-MM-DD')
        if (dateMap[date] === undefined) {
          dateMap[date] = { name: date, count: 1 }
          dateList.push(dateMap[date])
        } else {
          dateMap[date].count++
        }
      })
      return dateList.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
    },
    filterdList() {
      if (this.eventFilter.length > 0) {
        return this.logListv2.filter((log) => this.eventFilter.includes(log.event_type_id))
      } else {
        return this.logListv2
      }
    },
  },
  async created() {
    if (this.user.history_start) {
      await this.onUpdateDate(this.initDates)
    } else {
      let timeout = () => {
        clearTimeout(this.tiemoutId)
        this.tiemoutId = setTimeout(async () => {
          if (this.user.history_start) {
            await this.onUpdateDate(this.initDates)
          } else {
            timeout()
          }
        }, 500)
      }
      timeout()
    }
  },
  beforeDestroy() {
    clearTimeout(this.tiemoutId)
  },
  methods: {
    ...mapActions(['getLogListByPeriodInHistory']),
    async onSaveFilter(eventTypeList) {
      this.$log.debug('EventFilter#onSaveFilter', eventTypeList)
      this.eventFilter = eventTypeList
      this.$emit('filter', this.eventFilter)
      this.initFocus()
    },
    onChangeSite(item) {
      this.$log.debug('EventFilter#onChangeSite', item)
      this.selectDate = item.name
      this.$emit('selectDate', this.selectDate)
    },
    async onUpdateDate(dates = this.period, keepFocus = false) {
      this.dateLoading = true
      this.period = dates
      this.$emit('setLogListLoading', true)
      const startDate = moment(dates[0]).startOf('day').format()
      const endDate = moment(dates[1]).endOf('day').format()
      await this.getLogListByPeriodInHistory({ startDate, endDate, inChild: true, inRepOrg: true, inNoti: false, inRespStat: false, tabIndex: 1 })
      this.dateLoading = false
      this.$emit('setLogListLoading', false)
      !keepFocus && this.initFocus()
    },
    initFocus() {
      this.$log.debug('EventFilter#initFocus', this.dateList)
      if (this.dateList.length) {
        this.selectDate = this.dateList[0].name
      } else {
        this.selectDate = ''
        // this.$store.state.currentLog = null
      }
      this.$log.debug(this.selectDate)
      this.$emit('selectDate', this.selectDate)
    },
    async onClickDownload() {
      this.$log.debug('EventFilter#onClickDownload')
      if (this.dateList.length) {
        try {
          const start_date = moment(this.period[0]).startOf('day').format()
          const end_date = moment(this.period[1]).endOf('day').format()
          const event_type_id = this.eventFilter.length ? this.eventFilter : this.eventTypeList.filter((type) => !!type.id).map((event) => event.id)
          const time_zone = moment.tz.guess()
          const { data } = await alert.downloadLogHistory({ start_date, end_date, event_type_id, time_zone })
          var link = document.createElement('a')
          link.target = '_blank'
          link.href = Util.makeUrl(process.env.VUE_APP_CDN_URL, data.xlsx_path)
          link.click()
        } catch (e) {}
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hisotry-filter {
  width: 0;
  flex-grow: 1;
  min-width: 450px;
  min-height: 489px;
  max-height: 489px;
  margin: 20px 8px 0 30px;
  padding: 21px 30px 25px;
  border-radius: 24px;
  color: var(--f-text-black);
  background-color: var(--f-light-gray-1);
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-bg-middle);
  }
}

.date-filter-top {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
}

.progress-circular {
  width: 100%;
  height: 351px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
