<template>
  <div class="date-filter">
    <div :class="`date-period ${themeName}`">{{ datePeriod }}</div>
    <v-menu
      v-model="openDatepicker"
      offset-y
      nudge-bottom="5px"
      :close-on-content-click="false"
      :close-on-click="false"
      content-class="calendar-layout"
    >
      <template v-slot:activator="{ on, attrs }">
        <Button class="schedule" colorStrength="90" v-bind="attrs" v-on="on"
          ><img src="@/assets/Calendar_Filled_24px_Icon.svg" width="24px"
        /></Button>
      </template>
      <Calendar
        v-if="openDatepicker"
        :min-date="calendarMinDate"
        :max-date="calendarMaxDate"
        @updateCustomDate="onUpdateDate"
        style="border-radius: 15px"
        @closeCalendar="closeCalendar"
      ></Calendar>
    </v-menu>

    <Button class="download" @click="$emit('download')" colorStrength="90" :disabled="isViewer || !dateList.length">
      <img v-if="!isViewer && !!dateList.length" src="@/assets/Download_Filled_24px_Icon.svg" width="24px" />
      <img v-else src="@/assets/Download_Filled_24px_Icon-light.svg" width="24px" />
    </Button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import Button from '@common/Button'
import Calendar from '@item/calendar/DefaultCalendar'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { Button, Calendar },
  props: {
    dateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      openDatepicker: false,
      // period: [moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      calendarMaxDate: moment(),
    }
  },
  created() {
    const beforeSixDays = moment().startOf('day').subtract(6, 'days')
    const contractStartDate = moment(this.user.history_start)
    const diff = contractStartDate.isSameOrAfter(beforeSixDays)
    if (diff)
      this.setHistoryViewSelectedDateRangeAction({
        startDate: moment(this.user.history_start).format('YYYY-MM-DD'),
        endDate: this.period[1],
      })
  },
  computed: {
    ...mapState(['user', 'historyViewSelectedDateRange']),
    period() {
      return this.historyViewSelectedDateRange
    },
    datePeriod() {
      if (this.period.length) return `${this.period[0]} ~ ${this.period[1]}`
      return ''
    },
    calendarMinDate() {
      const beforeSixMonth = moment().startOf('day').subtract(180, 'days').subtract(1, 'days')
      const contractStartDate = moment(this.user.history_start).subtract(1, 'days')
      const diff = beforeSixMonth.isSameOrAfter(contractStartDate)
      if (diff) return beforeSixMonth
      return contractStartDate
    },
    isViewer() {
      return this.user.isViewer
    },
  },
  methods: {
    ...mapActions(['setHistoryViewSelectedDateRangeAction']),
    closeCalendar() {
      this.openDatepicker = false
    },
    onUpdateDate(dates) {
      this.openDatepicker = false
      this.setHistoryViewSelectedDateRangeAction({
        startDate: dates.startDate.format('YYYY-MM-DD'),
        endDate: dates.endDate.format('YYYY-MM-DD'),
      })
      this.$emit('update', [...this.period])
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar-layout {
  border-radius: 15px !important;
  width: 500px;
  overflow-y: unset;
  box-shadow: none !important;
  padding: 15px 20px 20px 20px;
  min-height: 550px;
  transform: translateY(-3%);
}
.date-filter {
  display: flex;

  .date-period {
    display: flex;
    align-items: center;
    width: 298px;
    height: 36px;
    padding: 0 16px;
    font-weight: 400;
    font-size: 14px;
    color: var(--f-text-black);
    background-color: var(--f-text-white);
    &.dark {
      color: var(--f-text-white-high);
      background-color: var(--f-bg-high);
    }
    border-radius: 8px;
    margin-right: 10px;
  }

  .schedule {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  .download {
    width: 36px;
    height: 36px;
  }
}
</style>
