<template>
  <div class="history">
    <section :class="`${themeName}`" v-scroll.self="handleOnScrollDirective">
      <div class="search-container">
        <EventFilter
          ref="eventFilter"
          @selectDate="selectDate = $event"
          @filter="eventFilter = $event"
          @setLogListLoading="setLogListLoading"
        ></EventFilter>
        <img v-if="$vuetify.theme.dark" src="@/assets/Chevron-Right_Circle_24px_2_Icon.svg" width="24px" />
        <img v-else src="@/assets/Chevron-Right_Circle_24px_2_Icon-red.svg" width="24px" />
        <LogList v-if="!logListLoading" ref="logList" :selectDate="selectDate" :eventFilter="eventFilter"></LogList>
        <div v-else :class="`progress-circular ${themeName}`">
          <v-progress-circular indeterminate color="#D8D9DA" />
        </div>
      </div>
      <CameraInfo v-if="historyViewSelectedDateList.length && currentCam" style="margin-top: 10px"></CameraInfo>
      <LogInfo
        v-if="historyViewSelectedDateList.length && currentLog"
        :edit="true"
        @update="onUpdateDate"
        style="margin-top: 10px"
        @onClickRootLog="onClickRootLog"
      ></LogInfo>
      <WeatherInfo v-if="historyViewSelectedDateList.length && currentLog" style="margin: 0 30px 20px"></WeatherInfo>
      <div class="player" v-if="historyViewSelectedDateList.length">
        <ImagePlayer v-if="currentLog" :currentLog="currentLog" ref="DetectionImagePlayerContainer">
          <template v-slot="{ currentIndex, moveFrame, playerState, imageUrlList, detectedImageIndex, hasAfterImages, play, pause, currentLogId }">
            <FramePlayerBar
              :currentLogId="currentLogId"
              :currentIndex="currentIndex"
              @moveFrame="moveFrame"
              :playerState="playerState"
              :imageUrlList="imageUrlList"
              :detectedImageIndex="detectedImageIndex"
              :hasAfterImages="hasAfterImages"
              @play="play"
              @pause="pause"
            />
          </template>
        </ImagePlayer>
        <LiveViewPlayer v-else-if="currentCam" :currentCam="currentCam" />
      </div>

      <div v-if="historyViewSelectedDateList.length" class="map-container" @mousewheel="onMousewheel" @keydown="onKeydown" @keyup="onKeyup">
        <Loading
          v-if="currentLog && currentLog.direction === null"
          :loading="true"
          style="margin: 0 30px; min-height: 420px; position: relative; width: calc(100% - 60px)"
        ></Loading>
        <Map v-else ref="historyMap" :currentCam="currentCam" :currentLog="currentLog" style="margin: 0 30px; min-height: 420px; position: relative">
          <div class="text-map">Direction may differ in the range of ±5˚.</div>
        </Map>
        <div :class="{ 'map-view-scroll-guide': true, visible: isInvalidScroll, hidden: !isInvalidScroll }" style="margin: 0 30px; min-height: 420px">
          Use Ctrl + scroll to zoom the map
        </div>
        <v-btn bottom class="map-center-btn" color="white" dark fab height="38" width="38" left @click="onClickMapCenterBtn()">
          <img src="@/assets/Arrow_Out_Circle_Icon_Red.svg" />
        </v-btn>
        <div class="legend">
          <MapDetailLegend />
        </div>
      </div>
      <v-spacer />
      <Footer></Footer>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EventFilter from '@desktop/adminOrViewer/history/EventFilter'
import LogList from '@desktop/adminOrViewer/history/LogList'
import CameraInfo from '@desktop/adminOrViewer/detection/CameraInfo'
import LogInfo from '@desktop/adminOrViewer/detection/LogInfo'
import WeatherInfo from '@desktop/adminOrViewer/detection/WeatherInfo'
import ImagePlayer from '@common/newImage/ImagePlayer'
import LiveViewPlayer from '@common/newImage/LiveViewPlayer'
import FramePlayerBar from '@common/newImage/FramePlayerBar'
// import Map from '@common/GoogleMaps/Map'
import Map from '@common/ArcGIS/HistoryMap'
import MapDetailLegend from '@common/MapDetailLegend.vue'
import Loading from '@common/ImagePlayer/Loading'
import Footer from '@desktop/adminOrViewer/detection/Footer'
import RotuerMixin from '@/mixin/router.mixin'
import SelfInfoMixin from '@/mixin/selfinfo.mixin'
import Theme from '@/mixin/theme.mixin'
import Constants from '@/constants'

export default {
  mixins: [RotuerMixin, SelfInfoMixin, Theme],
  components: {
    Footer,
    EventFilter,
    LogList,
    CameraInfo,
    LogInfo,
    WeatherInfo,
    Map,
    Loading,
    MapDetailLegend,
    LiveViewPlayer,
    ImagePlayer,
    FramePlayerBar,
  },
  props: ['name', 'logId', 'tabIndex'],
  data() {
    return {
      searchText: '',
      selectDate: '',
      eventFilter: [],
      timerId: null,
      // zoom control restriction(s)
      isPressingControlKey: false,
      isInvalidScroll: false,
      invalidScrollTimeout: null,
      logListLoading: false,
    }
  },
  async created() {
    await this.initialize()
  },
  destroyed() {
    if (this.currentCam) this.setCurrentCam(null)
    clearTimeout(this.timerId)
  },
  computed: {
    ...mapState(['currentCam', 'currentLog', 'historyViewSelectedDateList']),
  },
  methods: {
    ...mapActions(['getLog', 'setCurrentLog', 'setCurrentCam']),
    async onClickRootLog(rootLog) {
      await this.$refs.logList.onClickRootLog(rootLog)
    },
    async initialize() {
      this.updateCurrentLog()
    },
    async onUpdateDate() {
      this.$refs.eventFilter.onUpdateDate(this.$refs.eventFilter.period, true)
    },
    setLogListLoading(loadingState) {
      this.logListLoading = loadingState
    },
    updateCurrentLog() {
      clearTimeout(this.timerId)
      this.timerId = setTimeout(async () => {
        if (this.currentLog && this.currentLog.log_id && this.currentLog.after_image_paths === null) {
          const newCurrentLog = await this.getLog(this.currentLog.log_id)
          if (newCurrentLog.after_image_paths !== null) this.setCurrentLog(newCurrentLog)
        }
        this.updateCurrentLog()
      }, Constants.INTERVAL_SITE_LOG)
    },
    handleOnScrollDirective() {
      try {
        this.$refs.eventFilter.$refs.FilterButton.open = false
        this.$refs.eventFilter.$refs.DateFilter.openDatepicker = false
      } catch (e) {
        this.$log.debug('History#handleOnScrollDirective error', e)
      }
    },
    onClickMapCenterBtn() {
      this.$refs.historyMap.centerMap()
    },
    onMousewheel(evt) {
      if (!this.isPressingControlKey) {
        this.isInvalidScroll = true
        clearTimeout(this.invalidScrollTimeout)
        this.invalidScrollTimeout = setTimeout(() => {
          this.isInvalidScroll = false
        }, 1000)
      }
    },
    onKeydown(evt) {
      if (evt.key === 'Control') {
        this.isPressingControlKey = true
      }
    },
    onKeyup(evt) {
      if (evt.key === 'Control') {
        this.isPressingControlKey = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.history {
  display: flex;
  height: 100%;
  overflow: auto;
}

section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 1000px;
  height: 0;
  min-height: calc(100% - 75px);
  overflow: auto;
  background-color: var(--f-text-white);

  &.dark {
    background-color: var(--f-bg-low);
  }
}

.player {
  flex-basis: 603px;
  flex-shrink: 0;
  padding: 0 30px;
  min-height: 60vh;
}

.search-container {
  display: flex;
}

.text-map {
  position: absolute;
  top: 15px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  color: var(--f-text-white-high);
  background-color: var(--f-bg-middle);
  min-width: 282px;
  height: 30px;
  border-radius: 4px;
}

.map-container {
  position: relative;

  .map-view-scroll-guide {
    height: 100%;
    width: calc(100% - 60px);
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 22px;
    font-weight: 400;
    transition-property: opacity;
    transition-duration: 0.8s;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    &.visible {
      opacity: 1;
    }
    &.hidden {
      opacity: 0;
    }
  }
}

.legend {
  position: absolute;
  bottom: 25px;
  left: 45px;
}

.map-center-btn {
  position: absolute;
  bottom: 157px;
  left: 45px;
}

.map-center-btn:hover {
  background-color: #f2f1f1 !important;
}

.progress-circular {
  width: 0;
  flex-grow: 1;
  min-width: 450px;
  min-height: 489px;
  max-height: 489px;
  margin: 20px 30px 0 8px;
  padding: 0px 10px 25px 30px;
  border-radius: 24px;
  background-color: var(--f-light-gray-1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.dark {
    background-color: var(--f-bg-middle);
  }
}
</style>
