<template>
  <div :class="`date-list ${themeName}`">
    <template v-if="!!data.length">
      <div
        v-for="item in data"
        :key="item.id"
        :class="{ 'date-item': true, focus: selectDate === item.name, dark: $vuetify.theme.dark }"
        @click="$emit('click', item)"
      >
        <div>{{ item.name }}</div>
        <v-spacer></v-spacer>
        <div :class="{ count: true, focus: selectDate === item.name, dark: $vuetify.theme.dark }">{{ item.count }}</div>
      </div>
    </template>
    <template v-else>
      <div :class="`no-data ${themeName}`">No data available.</div>
    </template>
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  /**
   * emits
   * click
   */
  props: {
    selectDate: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.date-list {
  min-height: 351px;
  max-height: 351px;
  overflow: auto;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }

  .no-data {
    margin-top: 110px;
    font-weight: 400;
    font-size: 14px;
    color: var(--f-text-black-middle);
    text-align: center;
    &.dark {
      color: var(--f-text-white-middle);
    }
  }
}

.date-item {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px 0 30px;
  cursor: pointer;
  font-weight: 500;
  &.focus {
    background-color: var(--f-primary-70);
    color: var(--f-text-white);
    &.dark {
      color: var(--f-text-white-high);
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--f-light-gray-2);
    &.dark {
      border-bottom: 1px solid var(--f-bg-high);
    }
  }

  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 22px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: var(--f-text-white);
    background-color: var(--f-primary-70);
    &.dark {
      color: var(--f-text-white-high);
      background-color: var(--f-bg-high);
    }

    &.focus {
      color: var(--f-primary-70);
      background-color: var(--f-supplementary-highlight);
    }
  }
}
</style>
