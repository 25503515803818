var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history"},[_c('section',{directives:[{name:"scroll",rawName:"v-scroll.self",value:(_vm.handleOnScrollDirective),expression:"handleOnScrollDirective",modifiers:{"self":true}}],class:("" + _vm.themeName)},[_c('div',{staticClass:"search-container"},[_c('EventFilter',{ref:"eventFilter",on:{"selectDate":function($event){_vm.selectDate = $event},"filter":function($event){_vm.eventFilter = $event},"setLogListLoading":_vm.setLogListLoading}}),(_vm.$vuetify.theme.dark)?_c('img',{attrs:{"src":require("@/assets/Chevron-Right_Circle_24px_2_Icon.svg"),"width":"24px"}}):_c('img',{attrs:{"src":require("@/assets/Chevron-Right_Circle_24px_2_Icon-red.svg"),"width":"24px"}}),(!_vm.logListLoading)?_c('LogList',{ref:"logList",attrs:{"selectDate":_vm.selectDate,"eventFilter":_vm.eventFilter}}):_c('div',{class:("progress-circular " + _vm.themeName)},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#D8D9DA"}})],1)],1),(_vm.historyViewSelectedDateList.length && _vm.currentCam)?_c('CameraInfo',{staticStyle:{"margin-top":"10px"}}):_vm._e(),(_vm.historyViewSelectedDateList.length && _vm.currentLog)?_c('LogInfo',{staticStyle:{"margin-top":"10px"},attrs:{"edit":true},on:{"update":_vm.onUpdateDate,"onClickRootLog":_vm.onClickRootLog}}):_vm._e(),(_vm.historyViewSelectedDateList.length && _vm.currentLog)?_c('WeatherInfo',{staticStyle:{"margin":"0 30px 20px"}}):_vm._e(),(_vm.historyViewSelectedDateList.length)?_c('div',{staticClass:"player"},[(_vm.currentLog)?_c('ImagePlayer',{ref:"DetectionImagePlayerContainer",attrs:{"currentLog":_vm.currentLog},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentIndex = ref.currentIndex;
var moveFrame = ref.moveFrame;
var playerState = ref.playerState;
var imageUrlList = ref.imageUrlList;
var detectedImageIndex = ref.detectedImageIndex;
var hasAfterImages = ref.hasAfterImages;
var play = ref.play;
var pause = ref.pause;
var currentLogId = ref.currentLogId;
return [_c('FramePlayerBar',{attrs:{"currentLogId":currentLogId,"currentIndex":currentIndex,"playerState":playerState,"imageUrlList":imageUrlList,"detectedImageIndex":detectedImageIndex,"hasAfterImages":hasAfterImages},on:{"moveFrame":moveFrame,"play":play,"pause":pause}})]}}],null,false,705789397)}):(_vm.currentCam)?_c('LiveViewPlayer',{attrs:{"currentCam":_vm.currentCam}}):_vm._e()],1):_vm._e(),(_vm.historyViewSelectedDateList.length)?_c('div',{staticClass:"map-container",on:{"mousewheel":_vm.onMousewheel,"keydown":_vm.onKeydown,"keyup":_vm.onKeyup}},[(_vm.currentLog && _vm.currentLog.direction === null)?_c('Loading',{staticStyle:{"margin":"0 30px","min-height":"420px","position":"relative","width":"calc(100% - 60px)"},attrs:{"loading":true}}):_c('Map',{ref:"historyMap",staticStyle:{"margin":"0 30px","min-height":"420px","position":"relative"},attrs:{"currentCam":_vm.currentCam,"currentLog":_vm.currentLog}},[_c('div',{staticClass:"text-map"},[_vm._v("Direction may differ in the range of ±5˚.")])]),_c('div',{class:{ 'map-view-scroll-guide': true, visible: _vm.isInvalidScroll, hidden: !_vm.isInvalidScroll },staticStyle:{"margin":"0 30px","min-height":"420px"}},[_vm._v(" Use Ctrl + scroll to zoom the map ")]),_c('v-btn',{staticClass:"map-center-btn",attrs:{"bottom":"","color":"white","dark":"","fab":"","height":"38","width":"38","left":""},on:{"click":function($event){return _vm.onClickMapCenterBtn()}}},[_c('img',{attrs:{"src":require("@/assets/Arrow_Out_Circle_Icon_Red.svg")}})]),_c('div',{staticClass:"legend"},[_c('MapDetailLegend')],1)],1):_vm._e(),_c('v-spacer'),_c('Footer')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }