<template>
  <div :class="`log-list ${themeName}`">
    <template v-if="!!logListv2.length">
      <div :class="`date ${themeName}`">{{ dateName }}</div>
      <div ref="wrapper" :class="`log-list__wrapper ${themeName}`">
        <div v-for="log in selectLogList" :key="log.log_id" :class="{ 'log-item': true, dark: $vuetify.theme.dark }">
          <div :class="{ 'log-item__info': true, select: selectedLogId === log.log_id, dark: themeName === 'dark' }">
            <EventCircle v-if="!isViewer" :value="log.event_type_id"></EventCircle>
            <div class="log-item__text" @click="onClickRootLog(log)">
              {{ logName(log) }}
            </div>
            <RepetitiveInfoTooltip v-if="log.is_checked_organization" :isRepetitive="false" />
            <img
              v-if="themeName === 'dark' && log.has_repetitive"
              @click="onClickCaret(log)"
              src="@/assets/Chevron-Down_24px_2_Icon_white.svg"
              :class="{ 'info-container-caret': true, rotate: openedId === log.log_id }"
              width="24px"
            />
            <img
              v-else-if="themeName === 'light' && log.has_repetitive"
              @click="onClickCaret(log)"
              src="@/assets/Chevron-Down_24px_2_Icon.svg"
              :class="{ 'info-container-caret': true, rotate: openedId === log.log_id }"
              width="24px"
            />
          </div>
          <div v-if="openedId === log.log_id && loading" class="progress-circular">
            <v-progress-circular indeterminate color="#D8D9DA"></v-progress-circular>
          </div>
          <div v-else-if="log.log_id === openedId && !loading && childLogs.length" :class="`duplicate-container ${themeName}`">
            <div
              v-for="childLog in childLogs"
              :key="childLog.id"
              :class="`duplicate-log ${themeName} ${selectedLogId === childLog.id ? 'select' : ''}`"
              @click="onClickChildLog(childLog.id)"
            >
              {{ logName(childLog) }}
              <RepetitiveInfoTooltip v-if="childLog.is_checked_organization" :isRepetitive="true" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div :class="`no-data ${themeName}`">No data available.</div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import Util from '@/util'
import EventCircle from '@common/EventCircle'
import RepetitiveInfoTooltip from '@common/RepetitiveInfoTooltip'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { EventCircle, RepetitiveInfoTooltip },
  props: {
    selectDate: {
      type: String,
      default: '',
    },
    eventFilter: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedLogId: -1,
      openedId: -1,
      loading: false,
      childLogs: [],
    }
  },
  async mounted() {
    if (this.selectDate) await this.initFocus()
  },
  watch: {
    async selectDate(val) {
      if (val) {
        await this.initFocus()
      } else {
        this.selectedLogId = -1
        this.openedId = -1
      }
    },
  },
  computed: {
    ...mapState(['currentLog', 'logListv2', 'user']),
    isViewer() {
      return this.user.isViewer
    },
    selectLogList() {
      return this.logListv2
        .filter((log) => {
          const matchDate = moment(log.created_at).format('YYYY-MM-DD') === this.selectDate
          if (this.eventFilter.length) {
            const matchEvent = this.eventFilter.includes(log.event_type_id)
            return matchDate && matchEvent
          }
          return matchDate
        })
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    },
    dateName() {
      if (this.selectLogList.length) {
        return moment(this.selectLogList[0].created_at).format('YYYY-MM-DD')
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['getLog', 'getChildLogs']),
    async initFocus() {
      if (this.selectLogList.length) {
        this.selectedLogId = this.selectLogList[0].log_id
        const initLog = await this.getLog(this.selectedLogId)
        this.$store.dispatch('setCurrentLog', initLog)
        this.$refs.wrapper.scrollTop = 0
      } else {
        this.selectedLogId = -1
      }
    },
    logName(log) {
      const date = Util.getOffsetDate(log.created_at, log.offset)
      return `[${date}] ${log.camera_name}`
    },
    async getChildLogOfClickedLog(id) {
      const childLogsData = await this.getChildLogs({ id, inRepOrg: true })
      this.childLogs = childLogsData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    },
    async selectLog(log) {
      this.selectedLogId = log.log_id
      const selectedLog = await this.getLog(log.log_id)
      this.$store.dispatch('setCurrentLog', selectedLog)
    },
    async onClickCaret(log) {
      if (log.log_id === this.openedId) {
        this.openedId = -1
        this.childLogs = []
      } else {
        this.openedId = log.log_id
        this.loading = true
        await this.selectLog(log)
        await this.getChildLogOfClickedLog(log.log_id)
        this.loading = false
      }
    },
    async onClickRootLog(log) {
      if (this.selectedLogId === log.log_id) {
        return
      } else if (this.openedId === log.log_id) {
        await this.selectLog(log)
      } else if (this.openedId !== log.log_id) {
        this.openedId = -1
        this.childLogs = []
        await this.selectLog(log)
      }
    },
    async onClickChildLog(logId) {
      if (this.currentLog.log_id !== logId) {
        this.selectedLogId = logId
        const selectedChildLog = await this.getLog(logId)
        this.$store.dispatch('setCurrentLog', selectedChildLog)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.log-list {
  width: 0;
  flex-grow: 1;
  min-width: 450px;
  min-height: 489px;
  max-height: 489px;
  margin: 20px 30px 0 8px;
  padding: 0px 10px 25px 30px;
  border-radius: 24px;

  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
  }

  .no-data {
    margin-top: 203px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: var(--f-text-black-middle);
    &.dark {
      color: var(--f-text-white-middle);
    }
  }

  .date {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin: 25px 0 13px;

    color: var(--f-text-black);
    &.dark {
      color: var(--f-text-white-high);
    }
  }

  .log-list__wrapper {
    height: 83.6%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;
    &.duplicate-log:last-child {
      border-bottom: none;
    }
    &.dark {
      border-bottom: 1px solid #323340;
    }
  }

  .log-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 42px;
    font-size: 14px;

    color: var(--f-text-black);
    &.dark {
      color: var(--f-text-white-high);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--f-light-gray-2);
      &.dark {
        border-bottom: 1px solid var(--f-bg-high);
      }
    }
    div .log-item-time {
      font-weight: 500;
    }
  }

  .log-item__info {
    height: 47px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    .log-item__text {
      margin-left: 10px;
      cursor: pointer;
    }
    &.select {
      background: linear-gradient(0deg, #ececec, #ececec), #f9f9f9;

      &.dark {
        background: none;
        background-color: var(--f-bg-high);
      }
    }
  }
}

.info-container-caret {
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;
  &.rotate {
    transform: rotate(-180deg);
  }
}

.repetitive-logs {
  margin-left: 60px;
}

.duplicate-container {
  max-height: 235px;
  overflow: auto;
  border-top: 1px solid var(--f-light-gray-2);
  &.dark {
    border-top: 1px solid var(--f-bg-high);
    border-bottom: 1px solid #323340;
  }
}

.duplicate-log {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 0 15px 60px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border-bottom: 1px solid var(--f-light-gray-2);
  &.select {
    background: linear-gradient(0deg, #ececec, #ececec), #f9f9f9;
    &.dark {
      background: none;
      background-color: var(--f-bg-high);
    }
  }
  &.dark {
    border-bottom: 1px solid var(--f-bg-high);
  }
  &:last-child {
    border-bottom: none;
  }
}
.progress-circular {
  display: flex;
  justify-content: center;
  height: 80px;
  align-items: center;
}
</style>
