<template>
  <v-menu v-model="open" offset-x nudge-right="8px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <Button class="filter-button" :disabled="isViewer" v-bind="attrs" v-on="on" :invert="selected" colorStrength="90">
        <img v-if="selected" src="@/assets/Filter_Line_16px_Icon-red.svg" width="16px" style="margin-right: 8px" />
        <img v-else-if="!isViewer && $vuetify.theme.dark" src="@/assets/Filter_Line_16px_Icon-dark.svg" width="16px" style="margin-right: 8px" />
        <img v-else-if="!isViewer" src="@/assets/Filter_Line_16px_Icon-light.svg" width="16px" style="margin-right: 8px" />
        <img v-else src="@/assets/Filter_Line_16px_Icon-disabled.svg" width="16px" style="margin-right: 8px" />
        Filter
      </Button>
    </template>
    <div :class="`filter-list ${themeName}`">
      <div v-for="event in types" :key="event.eventTypeId" class="event-item" @click="onClickEventType(event)">
        <img v-if="event.check" src="@/assets/Checkbox-On_16px_2_Icon.svg" width="16px" />
        <img v-else-if="$vuetify.theme.dark" src="@/assets/Checkbox-Off_16px_2_Icon.svg" width="16px" />
        <img v-else src="@/assets/Checkbox-Off_16px_2_Icon-light.svg" width="16px" />
        <div>{{ event.name }}</div>
      </div>
      <Button class="button" :colorStrength="90" @click="onClickSave">Save</Button>
    </div>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Button from '@common/Button'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { Button },
  props: {
    eventFilter: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      open: false,
      types: [
        { eventTypeId: 2, name: 'Fire', check: false },
        { eventTypeId: 3, name: 'Non-Fire', check: false },
        { eventTypeId: 4, name: 'Unknown', check: false },
        { eventTypeId: 5, name: 'Planned Burn', check: false },
        { eventTypeId: 1, name: 'Unselected', check: false },
      ],
      selected: false,
    }
  },
  computed: {
    ...mapState(['user']),
    filterTypeList() {
      return this.types.filter((type) => type.check).map((type) => type.eventTypeId)
    },
    isViewer() {
      return this.user.isViewer
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.initialize()
      }
    },
  },
  methods: {
    initialize() {
      this.types.forEach((type) => {
        type.check = this.eventFilter.includes(type.eventTypeId)
      })
    },
    onClickEventType(event) {
      event.check = !event.check
    },
    onClickSave() {
      this.open = false
      this.selected = !!this.filterTypeList.length
      this.$emit('save', this.filterTypeList)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 34px;
  font-weight: 600;
  font-size: 14px;
}
.filter-list {
  display: flex;
  flex-direction: column;
  width: 157px;
  height: 208px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding-top: 14px;
  color: var(--f-text-black);
  background-color: var(--f-text-white);
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-bg-high);
  }

  .event-item {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 0 16px 11px;
    cursor: pointer;

    img {
      margin-right: 10px;
    }
  }
}

.button {
  align-self: center;
  width: 80px;
  height: 30px;
  font-weight: 600;
  font-size: 14px;
  color: var(--f-text-white-high);
  margin-top: 4px;
}
</style>
